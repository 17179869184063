import React from 'react'
import "./background-logo.scss"

const BackgroundLogo = () => {
  return (
   <div className='welcome'>
     <div className='background-logo'>
        
    </div>
   </div>
  )
}

export default BackgroundLogo